import { graphql } from "gatsby";

import Finder from "../../components/pages/GasStations/Finder";

export default Finder;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/finder" }) {
            ...Page
        }
    }
`;
